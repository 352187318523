.sidebar {
    background-color: #FFF1DC;
    width: 250px;
    height: 100vh;
    float: left;
}

.sidebar li {
    margin: 15px;
    list-style-type: none;
    font-size: larger;
    font-weight: 500;
    text-shadow: 0.5px 0.5px #b2b1b1;
    cursor: context-menu;
}
